import axios from "axios";
import store from '@/store/store.js';

export default {
	async loginUser(data) {
		try {
			const response = await axios.post(`/api/rest-auth/login/`, data);
			if (response.headers["authorization"]) {
				localStorage.setItem("auth-token", JSON.stringify(response.headers["authorization"]))
			}
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			}
		}
	},
	async checkFor2FAPermission(data) {
		try {
			const response = await axios.post(`/api/check_for_2fa/`, data);
			if (response.headers["authorization"]) {
				localStorage.setItem("auth-token", JSON.stringify(response.headers["authorization"]))
			}
			return response.data
		} catch (error) {
			return {
				error: true,
				error_message: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			}
		}
	},
	async getPublicKey() {
		try {
			const response = await axios.get(`/api/registration/public/get_key/`);
			return response.data;
		}catch (error) {
			return {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
		}
	},
	async resetPassword(data){
		try{
			const response = await axios.post(`/api/rest-auth-mod/password/reset_password/`, data);
			return response
		}catch (error) {
			return {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
		}
	},
	async verifyEmail(data){
		try{
			const response = await axios.post(`/api/rest-auth/verify-email/`, data);
			return response
		}catch (error) {
			return {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
		}
	},
	async passwordComplexity(data){
		try{
			const response = await axios.post(`/api/rest-auth-mod/password/complexity/`, {'key': data});
			return response
		}catch (error) {
			return {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
		}
	},
	async passwordPolicy(data){
		try{
			const response = await axios.post(`/api/rest-auth-mod/password/policy/`, {'key': data});
			return response
		}catch (error) {
			return {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
		}
	},
	async changePassword(data){
		try{
			const response = await axios.post(`/api/user/change_password/`, data);
			return response
		}catch (error) {
			return {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
		}
	},
	async resetPasswordConfirm(data){
		try{
			const response = await axios.post(`/api/rest-auth-mod/password/reset/confirm/`, data);
			return response
		}catch (error) {
			return {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
		}
	},

	async reissueUserWelcomeEmail(user_id) {
		try{
			const response = await axios.get(`/api/reissue_welcome_email_to_user/${user_id}/`);
			return response
		}catch (error) {
			return {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
		}
	},
	async reissue2FAQRCode (user_id) {
		try {
			const response = await axios.get(`/api/reissue_2fa_qr_code_to_user/${user_id}`)
			return response
		} catch (error) {
			return {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
		}
	},
	async loginHelpEmail(data){
		try{
			const response = await axios.post(`/api/user/login-faq/`, data);
			return response
		}catch (error) {
			return {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
		}
	},
	async getBranding(brand){
		try{
			const response = await axios.get(`/api/customer_group_brand/` + brand)
			return response
		}catch (error) {
			return {
				error: true,
				data: error.response.data,
				statusText: error.response.statusText,
				statusCode: error.response.status,
			};
		}

	},
}
