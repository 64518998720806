<template>
	<vue-recaptcha 
		v-if="site_key"
		:sitekey='site_key'
		:size="$q.screen.lt.sm ? 'compact' : 'normal'"
		@verify="verifyCaptcha"
	></vue-recaptcha>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha';
import LoginAPI from '@/services/api/Login.js';
export default ({
	name: 'LoginRecaptcha',
	components: {
		VueRecaptcha,
		LoginAPI
	},
	data() {
		return{
			site_key: '',
			recaptcha_verified: false,
		}
	},
	methods:{
		verifyCaptcha(response){
			this.$emit('recaptcha-verified', true)
			this.$emit('recaptcha-verification-code', response)
		},
	},
	async created(){
		this.site_key = await LoginAPI.getPublicKey();
		if (this.site_key.error) {
			this.site_key = "";
		}
	},
})
</script>
